/* Home.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #F4F4F4;
    color: #333;
  }
  
  .home-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #023047, #2E3A59);
    color: white;
    padding: 50px 20px;
    text-align: center;
  }
  
  h1 {
    font-size: 4rem;
    font-weight: 800;
    margin-bottom: 20px;
    color: #FFB703;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #FFFFFF;
    font-weight: 300;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 50px;
    max-width: 700px;
    line-height: 1.7;
    color: #FFFFFF;
  }
  
  .cta-buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .cta-buttons a {
    text-decoration: none;
    padding: 15px 40px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 30px;
    color: #2E3A59;
    background-color: #FFB703;
    transition: all 0.3s ease;
  }
  
  .cta-buttons a:hover {
    background-color: #FB8500;
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  footer {
    margin-top: 60px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
  }
  
  footer a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    p {
      font-size: 1rem;
    }
  
    .cta-buttons a {
      font-size: 1rem;
      padding: 10px 30px;
    }
  }
  
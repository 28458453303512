/* Games.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #F4F4F4;
    color: #333;
  }
  
  .games-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #2E3A59;
    padding: 60px 20px;
  }
  
  h1 {
    font-size: 3rem;
    font-weight: 700;
    color: #FFB703;
    margin-bottom: 40px;
  }
  
  .game-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    width: 100%;
    max-width: 1200px;
  }
  
  .game-list a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #023047;
    padding: 20px;
    border-radius: 12px;
    text-decoration: none;
    color: white;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .game-list a:hover {
    background-color: #FFB703;
    color: #2E3A59;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .game-list img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }
  
  footer {
    margin-top: 60px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
  }
  
  footer a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
  
    .game-list {
      grid-template-columns: 1fr;
    }
  }
  